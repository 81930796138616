import { Link } from "@biblioteksentralen/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLink from "../../InternalLink";
import { PRIVACY_POLICY_PATH } from "@libry-content/common";
import { FooterMenuList } from "./FooterMenuList";
import { ExternalLink } from "react-feather";
import { useCommonData } from "../CommonDataProvider";
import { sift } from "radash";

export const SiteFooterMenuItems = () => {
  const accesibilityStatement = useCommonData().headerFooterData?.accesibilityStatement;
  const { t } = useTranslation();

  const tilgjengelighetserklæringer = sift([
    accesibilityStatement?.web && {
      url: accesibilityStatement?.web,
      title: t("Tilgjengelighetserklæring"),
    },
    accesibilityStatement?.app_android && {
      url: accesibilityStatement?.app_android,
      title: t("Tilgjengelighetserklæring Android (App)"),
    },
    accesibilityStatement?.app_ios && {
      url: accesibilityStatement?.app_ios,
      title: t("Tilgjengelighetserklæring iOS (App)"),
    },
  ]);

  return (
    <FooterMenuList>
      <li>
        <InternalLink href={PRIVACY_POLICY_PATH} variant="plain">
          {t("Personvernerklæring")}
        </InternalLink>
      </li>
      {tilgjengelighetserklæringer.map(({ url, title }) => (
        <li key={title}>
          <Link display="inline-flex" alignItems="center" gap=".3rem" href={url} variant="plain">
            {title}
            <ExternalLink size="1em" />
          </Link>
        </li>
      ))}
      <li>
        <InternalLink href="/nettstedkart" variant="plain">
          {t("Nettstedkart")}
        </InternalLink>
      </li>
    </FooterMenuList>
  );
};
